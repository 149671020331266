import {
  Box,
  FormHelperText,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { Option } from '@api/types/option';
import RadioSelectedIcon from '@assets/svgs/radio-selected.svg';
import RadioUnselectedIcon from '@assets/svgs/radio-unselected.svg';
import { ReactComponent as CircleQuestion } from '@assets/svgs/circle-question.svg';
import { ReactComponent as CircleQuestionFull } from '@assets/svgs/circle-question-full.svg';
import { DefaultTFuncReturn } from 'i18next';
import { Gap } from '@components/layout-util-components/gap';
import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { HtmlTooltip } from '@components/tooltip/default-tooltip';

interface RadioSelectGroupOption<T> extends Option<T> {
  tooltip?: React.ReactNode;
}

interface Props<T> {
  label?: string | DefaultTFuncReturn;
  error?: string | null;
  options: Array<RadioSelectGroupOption<T>>;
  value: T;
  onChange: (value: T) => void;
}

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  maxWidth: 450,
}));

const StyledIndicator = styled('img')<{
  isSelected: boolean;
}>(({ isSelected, theme }) => ({
  width: 30,
  height: 30,
  marginRight: 16,
  ...(isSelected && {
    '& svg': {
      color: theme.palette.primary.main,
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  }),
}));

const StyledOption = styled('div')<{
  displayBorder: boolean;
  isSelected: boolean;
}>(({ displayBorder, isSelected, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 10,
  borderRadius: 0,
  backgroundColor: 'white',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.common.grey50}`,
  marginBottom: '-1px',
  position: 'relative',
  '&:first-child': {
    borderRadius: '6px 6px 0 0',
  },
  '&:last-child': {
    borderRadius: '0 0 6px 6px',
    marginBottom: 0,
  },
  // ...(displayBorder && {
  //   borderRadius: '6px 6px 0 0',
  //   border: `1px solid ${theme.palette.common.grey50}`,
  // }),
  ...(isSelected && {
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: 'default',
    zIndex: 1,
  }),
}));

const HoverableIcon = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  marginLeft: 'auto',
  marginRight: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& .default-icon': {
    position: 'absolute',
    opacity: 1,
    transition: 'opacity 0.3s ease',
  },
  '& .hover-icon': {
    position: 'absolute',
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  '&:hover .default-icon': {
    opacity: 0,
  },
  '&:hover .hover-icon': {
    opacity: 1,
  },
}));

const StyledCircleQuestion = styled(CircleQuestion)(({ theme }) => ({
  width: 20,
  height: 20,
}));

const StyledCircleQuestionFull = styled(CircleQuestionFull)(({ theme }) => ({
  width: 20,
  height: 20,
}));

export default function RadioSelectGroup<T>({
  options,
  label,
  error,
  onChange,
  value,
}: Props<T>) {
  const [open, setOpen] = useState<T>();

  const handleTooltipClose = () => {
    setOpen(undefined);
  };

  const handleTooltipOpen = (value: T) => {
    setOpen(value);
  };

  return (
    <>
      {label ? (
        <>
          <Typography>{label}</Typography>
          <Gap size={15} />
        </>
      ) : null}
      <StyledContainer>
        {options.map(({ id, label, tooltip }, index) => {
          const isSelected = id === value;

          return (
            <StyledOption
              key={`${id}`}
              displayBorder={index < options.length - 1}
              isSelected={isSelected}
              onClick={() => onChange(id)}
            >
              <StyledIndicator
                isSelected={isSelected}
                src={isSelected ? RadioSelectedIcon : RadioUnselectedIcon}
                alt="radio option indicator"
              />
              <span>{label}</span>
              {tooltip && (
                <HtmlTooltip
                  onClose={handleTooltipClose}
                  open={open === id}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={tooltip}
                  arrow
                  clickedAway={handleTooltipClose}
                  slotProps={{
                    popper: {
                      disablePortal: true,
                    },
                  }}
                >
                  <HoverableIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTooltipOpen(id);
                    }}
                  >
                    <StyledCircleQuestion className="default-icon" />
                    <StyledCircleQuestionFull className="hover-icon" />
                  </HoverableIcon>
                </HtmlTooltip>
              )}
            </StyledOption>
          );
        })}
      </StyledContainer>
      {error ? (
        <Box mx={2}>
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      ) : null}
    </>
  );
}
