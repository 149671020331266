import { Box, Grid, styled } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import { GroupCard } from '@components/cards';
import { LinkButton, PrimaryButton } from '@components/buttons';
import GroupForm from '@pages/app/groups/forms/group-form';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Permission } from '@api/types/permission';
import useManageGroups from '@pages/app/groups/manage-groups-hook';
import {
  DropDownFilter,
  SortByFilter,
  WCTTableFilters,
} from '@components/table';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import NoGroupsInfo from './components/no-groups-info';
import ContentContainer from '@components/containers/content-container';
import { Gap } from '@components/layout-util-components/gap';
import useOpenGroupOnMount from '@hooks/groups/use-open-group-on-mount';
import { ButtonSkeleton } from '@components/skeletons';
import GridSkeleton from '@components/skeletons/grid-skeleton';
import NoFilteredGroupsInfo from './components/no-filtered-groups-info';
import PageCount from '@components/typography/page-count';

type SearchParams = {
  companyGroupId: string;
};

const GroupsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: 25,
  width: '100%',
});

function ManageGroupsPage() {
  const { companyGroupId } = useSearchParamsTyped<SearchParams>();
  const { t } = useAppTr('groups');
  const { showPopup } = useUiPopup();

  const {
    groups,
    isLoading,
    isFetching,
    search,
    loadMore,
    sort,
    sortByOptions,
    showLoadMoreButton,
    companyUser,
    userOptions,
    loadingGroups,
    hasNoGroups,
    hasNoFilteredGroups,
    hasFilteredGroups,
    canCreateGroup,
  } = useManageGroups();

  const showCreateGroup = () => {
    showPopup(GroupForm);
  };

  useOpenGroupOnMount(companyGroupId);

  return (
    <ContentContainer>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageCount
          title="Groups"
          isLoading={isLoading}
          count={groups?.totalCount}
        />
        <Gap size={12} />
        {canCreateGroup &&
          (isLoading ? (
            <Box>
              <ButtonSkeleton />
            </Box>
          ) : !hasNoGroups ? (
            <PrimaryButton disabled={isLoading} onClick={showCreateGroup}>
              {t('createButtonLabel')}
            </PrimaryButton>
          ) : null)}
      </Grid>
      <Gap size={20} />
      <WCTTableFilters
        search={search.value}
        onSearch={search.set}
        isLoading={isLoading}
        isFetching={isFetching}
        rightOfSearchContent={
          <SortByFilter
            value={sort.value}
            onChange={sort.set}
            options={sortByOptions}
          />
        }
      >
        {isLoading ? (
          <></>
        ) : (
          <>
            {userOptions && userOptions.length > 1 && (
              <DropDownFilter
                options={userOptions!}
                value={companyUser.value}
                onChange={companyUser.set}
              >
                User
              </DropDownFilter>
            )}
          </>
        )}
      </WCTTableFilters>
      <Gap size={32} />
      {hasNoGroups && <NoGroupsInfo onCreateClicked={showCreateGroup} />}
      {hasNoFilteredGroups && <NoFilteredGroupsInfo />}
      {hasFilteredGroups && (
        <GroupsContainer>
          {loadingGroups ? (
            <GridSkeleton count={4} />
          ) : (
            <>
              {groups?.model.map((group) => (
                <GroupCard
                  key={group.companyGroupId}
                  group={group}
                  allowLeave={false}
                />
              ))}
            </>
          )}
        </GroupsContainer>
      )}
      <Gap size={15} />
      <Box alignSelf="center">
        {showLoadMoreButton ? (
          <LinkButton disabled={isLoading} onClick={loadMore}>
            {t('loadMoreButtonLabel')}
          </LinkButton>
        ) : null}
      </Box>
    </ContentContainer>
  );
}

export default requireAuth(ManageGroupsPage, {
  permissions: [Permission.manageCompanyGroups],
});
