import { api } from '@api/api';
import { CreateChildThemeRequest } from '@api/types/theme/create-child-theme.request';
import { ThemeResource } from '@api/types/theme/theme.resource';
import { CreateThemeRequest } from '@api/types/theme/create-theme.request';
import { makeError } from '@api/types/api-error';
import { UpdateThemeRequest } from '@api/types/theme/update-theme.request';
import { maybeUpdateCache } from '@api/cache-util';

const companyThemeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getThemes: build.query<ThemeResource[], string | undefined>({
      query: (searchText) => ({
        url: 'company/theme',
        method: 'GET',
        params: {
          searchText,
        },
      }),
      providesTags: (response) =>
        response
          ? [
              ...response.map((data) => ({
                id: data.themeId,
                type: 'CompanyTheme' as const,
              })),
              'CompanyTheme',
            ]
          : [],
    }),
    enableTheme: build.mutation<void, number>({
      query: (id) => ({
        url: `company/theme/${id}/enable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CompanyTheme' }] : [],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        const patches = maybeUpdateCache('CompanyTheme', arg, [
          {
            api: companyThemeApi,
            endpoint: companyThemeApi.endpoints.getThemes,
            callback: (d: ThemeResource[]) => {
              d.forEach((theme) => {
                if (theme.themeId === arg) {
                  theme.disabled = undefined;
                }
              });
            },
          },
        ]);

        try {
          await queryFulfilled;
        } catch (e) {
          patches.forEach((x) => x.undo());
        }
      },
    }),
    disableTheme: build.mutation<void, number>({
      query: (id) => ({
        url: `company/theme/${id}/disable`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CompanyTheme' }] : [],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        const patches = maybeUpdateCache('CompanyTheme', arg, [
          {
            api: companyThemeApi,
            endpoint: companyThemeApi.endpoints.getThemes,
            callback: (d: ThemeResource[]) => {
              d.forEach((theme) => {
                if (theme.themeId === arg) {
                  theme.disabled = Date().toString();
                }
              });
            },
          },
        ]);

        try {
          await queryFulfilled;
        } catch (e) {
          patches.forEach((x) => x.undo());
        }
      },
    }),
    createTheme: build.mutation<ThemeResource, CreateThemeRequest>({
      query: (body) => ({
        url: 'company/theme',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, error) => (!error ? ['CompanyTheme'] : []),
      transformErrorResponse: (error) => makeError<CreateThemeRequest>(error),
    }),
    createChildTheme: build.mutation<void, CreateChildThemeRequest>({
      query: (body) => ({
        url: 'company/theme/child',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<CreateChildThemeRequest>(error),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [
              'CompanyTheme',
              { id: arg.parentId, type: 'CompanyTheme' as const },
            ]
          : [],
    }),
    updateTheme: build.mutation<void, UpdateThemeRequest>({
      query: (body) => ({
        url: 'company/theme',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError<UpdateThemeRequest>(error),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg.themeId, type: 'CompanyTheme' as const }] : [],
    }),
    deleteTheme: build.mutation<void, number>({
      query: (id) => ({
        url: `company/theme/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ id: arg, type: 'CompanyTheme' }] : [],
    }),
  }),
});

export const {
  useGetThemesQuery,
  useDisableThemeMutation,
  useEnableThemeMutation,
  useCreateThemeMutation,
  useCreateChildThemeMutation,
  useDeleteThemeMutation,
  useUpdateThemeMutation,
} = companyThemeApi;
