import { Palette } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const switchStyles = (palette: Palette): Components['MuiSwitch'] => ({
  styleOverrides: {
    root: {
      width: 40,
      height: 20,
      padding: 0,
      margin: '0 10px',
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#168664',
          opacity: 1,
          border: 0,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      borderRadius: 40 / 2,
      backgroundColor: '#666666',
      opacity: 1,
      transition: 'background-color 0.3s',
    },
  },
});
