import { PropsWithChildren, ReactNode } from 'react';
import { styled } from '@mui/material';
import CrossSellPlaceholder from '@assets/images/Sidebar-longer.png';

const BackgroundSVG = '/svgs/sidebar-header.svg';
const BackgroundFooterSVG = '/svgs/sidebar-footer.svg';

interface Props {
  appbar: ReactNode;
  sidebar?: ReactNode;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  height: '100vh',
});

const InnerContainer = styled('div')({
  flex: '1 1 auto',
  display: 'flex',
  alignItems: 'stretch',
  backgroundColor: '#ECEDF0',
  justifyContent: 'flex-start',
  '@media (max-width:1290px)': {
    '.helpBar': {
      display: 'none',
    },
  },
});

const ContentContainer = styled('div', {
  target: 'rca-form-scaffold-content-container',
})({
  display: 'flex',
  justifyContent: 'flex-start',
  flex: '1 1 auto',
  '> div ': {
    // maxWidth: '1200px',
    '@media (min-width:1800px)': {
      maxWidth: '1400px',
    },
  },
  '.custom-row': {
    flexWrap: 'wrap',
    gap: '15px',
    '@media (min-width:1024px)': {
      flexWrap: 'none',
      gap: '0px',
    },
  },
});

const SidebarContainer = styled('div')({
  // display: 'none',
  background: `url(${CrossSellPlaceholder}) left 100px no-repeat rgb(0, 34, 58)`,
  //background: `url(${BackgroundSVG}) no-repeat top left, url(${BackgroundFooterSVG}) no-repeat bottom left #00223A`,
  flex: '0 0 20%',
  height: 'calc(100vh - 80px)',
  padding: 0, // 25,
  '>*': {
    minHeight: '100%',
  },
  position: 'sticky',
  top: 80,
});

export default function RcaCreateScaffold({
  appbar,
  sidebar,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Container className="rca-create">
      {appbar}
      <InnerContainer>
        <ContentContainer>
          {children}
          {sidebar ? (
            <SidebarContainer className="helpBar">{sidebar}</SidebarContainer>
          ) : null}
        </ContentContainer>
      </InnerContainer>
    </Container>
  );
}
