import { OutlinedButton, PrimaryButton } from '@components/buttons';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import WCTTextField from '@components/input/text-field';
import Column from '@components/layout-util-components/column';
import { Gap } from '@components/layout-util-components/gap';
import SubPageTitle from '@components/menu-items/subpage-title';
import { InputSkeleton } from '@components/skeletons';
import useActionFooter from '@hooks/use-action-footer-hook';
import useSystemText from '@hooks/use-system-text';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useCompanySystemText from './company-system-text-hook';

function CompanySystemTextPage() {
  const footerStyle = useActionFooter();
  const { systemText } = useSystemText();
  const navigate = useNavigate();
  const {
    isLoading,
    focalPoint,
    businessFunctions,
    recurrence,
    typeOfImpact,
    impactCategory,
    isSubmitting,
    canSubmit,
    submit,
  } = useCompanySystemText();

  return (
    <>
      <SubPageTitle
        items={[
          { label: 'Company Profile', path: '/company-profile' },
          { label: 'RCA Settings', path: '/company-profile/rca-settings' },
          { label: 'Label Configuration' },
        ]}
      />
      <Gap size={32} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="h3">Step 1 - Overview</Typography>
          <Typography variant="body2">
            You can override the labels displayed within the overview stage of
            the 'Create RCA' process
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Column>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label={systemText['RCA.Overview.FocalPoint']}
                name="focalPoint"
                value={focalPoint.value}
                error={focalPoint.error}
                onChange={focalPoint.set}
                required
              />
            )}
            <Gap size={24} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label="Business Area"
                name="businessFunctions"
                value={businessFunctions.value}
                error={businessFunctions.error}
                onChange={businessFunctions.set}
                required
              />
            )}
            <Gap size={24} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label="Recurrence"
                name="recurrence"
                value={recurrence.value}
                error={recurrence.error}
                onChange={recurrence.set}
                required
              />
            )}
          </Column>
        </Grid>
      </Grid>
      <Gap size={20} />
      <hr />
      <Gap size={20} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="h3">Step 2 - Details</Typography>
          <Typography variant="body2">
            You can override the labels displayed within the details stage of
            the 'Create RCA' process
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Column>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label="Type of impact"
                name="typeOfImpact"
                value={typeOfImpact.value}
                error={typeOfImpact.error}
                onChange={typeOfImpact.set}
                required
              />
            )}
            <Gap size={24} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label="Impact Category"
                name="impactCategory"
                value={impactCategory.value}
                error={impactCategory.error}
                onChange={impactCategory.set}
                required
              />
            )}
          </Column>
        </Grid>
      </Grid>
      {/* <Gap size={20} />
      <hr />
      <Gap size={20} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Cause Box</Typography>
          <Typography variant="body2">
            You can override the labels displayed within the cause box
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Column>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label="Dynamic"
                name="transitory"
                value={transitory.value}
                error={transitory.error}
                onChange={transitory.set}
                required
              />
            )}
            <Gap size={24} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label="Static"
                name="nonTransitory"
                value={nonTransitory.value}
                error={nonTransitory.error}
                onChange={nonTransitory.set}
                required
              />
            )}
          </Column>
        </Grid>
      </Grid> */}
      {/* <Gap size={20} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Step 3 - Collaboration</Typography>
          <Typography variant="body2">
            You can override the labels displayed within the collaboration stage
            of the 'Create RCA' process
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Column>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label="Invite only / Public"
                name="inviteOnly"
                value={inviteOnly.value}
                error={inviteOnly.error}
                onChange={inviteOnly.set}
                required
              />
            )}
            <Gap size={24} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label="Share with groups"
                name="shareWithGroups"
                value={shareWithGroups.value}
                error={shareWithGroups.error}
                onChange={shareWithGroups.set}
                required
              />
            )}
          </Column>
        </Grid>
      </Grid> */}
      <Gap size={32} />
      <div style={footerStyle}>
        <OutlinedButton
          onClick={() => navigate('/company-profile/rca-settings')}
        >
          Cancel
        </OutlinedButton>
        <PrimaryButton
          disabled={!canSubmit}
          isBusy={isSubmitting}
          onClick={submit}
          type="submit"
        >
          Update Settings
        </PrimaryButton>
      </div>
    </>
  );
}

export default requireAuth(CompanySystemTextPage);
