import { FormHelperText, Slider, SliderProps, styled } from '@mui/material';
import { ReactNode } from 'react';

export enum SliderFieldMaxSize {
  small = 269,
  medium = 350,
  Large = 500,
}

interface IMark {
  value: number;
  label: string | ReactNode;
}

interface HelperIndicators {
  left: string;
  right: string;
}

interface Props {
  name?: string;
  value?: number | number[];
  onChange: (value: number | number[]) => void;
  readonly?: boolean;
  maxWidth?: SliderFieldMaxSize;
  marks?: IMark[];
  helperIndicators?: HelperIndicators;
  step?: number;
  max?: number;
  displayValue?: boolean;
  formatValue?: (value: number | number[] | undefined) => string;
  error?: string;
  valueLabelDisplay?: 'on' | 'auto' | 'off';
  valueLabelStyles?: Record<number, any>;
}

const StyledIndicators = styled('div')({
  fontSize: '10px',
  fontWeight: '500',
  lineHeight: '14px',
  letterSpacing: '0px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  color: '#00000099',
});

const StyledValue = styled('div')({
  fontSize: '10px',
  fontWeight: '500',
  lineHeight: '14px',
  letterSpacing: '0px',
  textAlign: 'center',
  color: '#00000099',
});

interface StyledSliderProps extends SliderProps {
  valueLabelStyles?: Record<number, any>;
}

const StyledSliderField = styled(Slider)<StyledSliderProps>(
  ({ value, valueLabelStyles, theme: { palette } }) => ({
    '.MuiSlider-markLabel': {
      fontSize: '10px',
      textAlign: 'center',
    },
    '.MuiSlider-valueLabel': {
      background: '#00223A',
    },
    // eslint-disable-next-line quotes
    '.MuiSlider-thumb':
      value !== undefined &&
      valueLabelStyles !== undefined &&
      valueLabelStyles[value as number]
        ? valueLabelStyles[value as number]
        : {},
  })
);

export default function WCTSliderField({
  name,
  value,
  onChange,
  readonly,
  maxWidth,
  marks,
  helperIndicators,
  step,
  max,
  displayValue,
  formatValue,
  error,
  valueLabelDisplay,
  valueLabelStyles,
}: Props) {
  return (
    <>
      {displayValue && (
        <StyledValue>{formatValue ? formatValue(value) : value}</StyledValue>
      )}
      <StyledSliderField
        sx={{
          maxWidth: maxWidth,
          margin: '25px 10px',
          width: 'auto',
        }}
        name={name}
        value={value || 0}
        disabled={readonly}
        onChange={(_, value) => {
          onChange(value);
        }}
        marks={marks}
        step={step}
        max={max}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={formatValue ? () => formatValue(value) : () => value}
        valueLabelStyles={valueLabelStyles}
      />
      {helperIndicators && (
        <StyledIndicators>
          <span>{helperIndicators!.left}</span>
          <span>{helperIndicators!.right}</span>
        </StyledIndicators>
      )}
      {error && <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>}
    </>
  );
}
