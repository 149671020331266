import { darken, Palette } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const chipStyles = (palette: Palette): Components['MuiChip'] => ({
  styleOverrides: {},
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        padding: 8,
        borderColor: palette.mainBlue,
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '18.59px',
        letterSpacing: '0.17000000178813934px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',

        '.svg-inline--fa': {
          color: 'inherit',
          fontSize: '14px',

          '&:hover': {
            color: darken(palette.mainBlue as string, 0.2),
          },
        },

        '&.Mui-disabled': {
          borderColor: palette.disabledText,
          color: palette.disabledText,
          opacity: 1.0,
          pointerEvents: 'unset',

          '.svg-inline--fa': {
            pointerEvents: 'none',
          },
        },
      },
    },
  ],
});
