import { OutlinedButton, PrimaryButton } from '@components/buttons';
import LabelValueDisplay from '@components/data/label-value-display';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import WCTPhoneNumberField from '@components/input/phone-number-field';
import WCTSelectField from '@components/input/select-field';
import WCTTextField from '@components/input/text-field';
import { Divider } from '@components/layout-util-components/divider';
import { Gap } from '@components/layout-util-components/gap';
import { InputSkeleton } from '@components/skeletons';
import FormSubtitle from '@components/typography/form-subtitle';
import FormTitle from '@components/typography/form-title';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import useActionFooter from '@hooks/use-action-footer-hook';
import { useAppTr } from '@i18n/use-app-tr';
import { Grid, Link } from '@mui/material';
import ChangeEmailAddressModal, {
  ChangeEmailAddressModalProps,
} from '@pages/app/profile/forms/change-email-address-modal';
import ChangePhoneNumberModal, {
  ChangePhoneNumberModalProps,
} from '@pages/app/profile/forms/change-phone-number-modal';
import usePersonalDetails from '@pages/app/profile/personal-details-hook';

function PersonalDetailsPage() {
  const { t } = useAppTr('personalDetails');
  const footerStyle = useActionFooter();

  const {
    workLocations,
    canSubmit,
    submit,
    isLoading,
    isSubmitting,
    dialCodes,
    location,
    email,
    firstName,
    surname,
    phone,
    jobTitle,
    canAddCompanyLocation,
  } = usePersonalDetails();

  const { showConfirmationModal, showPopup } = useUiPopup();

  const onSubmit = async () => {
    let phoneChangeCode: string | undefined,
      emailChangeCode: string | undefined;
    if (phone.isDirty) {
      const shouldContinue = await showConfirmationModal({
        title: t('confirmMobileChangeModal.title'),
        message: t('confirmMobileChangeModal.message'),
        noButtonLabel: t('confirmMobileChangeModal.cancelButtonLabel'),
        yesButtonLabel: t('confirmMobileChangeModal.continueButtonLabel'),
      });

      if (!shouldContinue) {
        return;
      }

      phoneChangeCode = await showPopup<ChangePhoneNumberModalProps, string>(
        ChangePhoneNumberModal,
        {
          phone: phone.value!,
        }
      );

      // User cancelled...
      if (phoneChangeCode == null) {
        return;
      }
    }

    if (email.isDirty) {
      const shouldContinue = await showConfirmationModal({
        title: t('confirmEmailChangeModal.title'),
        message: t('confirmEmailChangeModal.message'),
        noButtonLabel: t('confirmEmailChangeModal.cancelButtonLabel'),
        yesButtonLabel: t('confirmEmailChangeModal.continueButtonLabel'),
      });

      if (!shouldContinue) {
        return;
      }

      emailChangeCode = await showPopup<ChangeEmailAddressModalProps, string>(
        ChangeEmailAddressModal,
        {
          email: email.value!,
        }
      );

      // User cancelled...
      if (emailChangeCode == null) {
        return;
      }
    }

    // noinspection ES6MissingAwait
    submit(phoneChangeCode, emailChangeCode);
  };

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <FormTitle text={t('form.details.title')} />
          <FormSubtitle text={t('form.details.subtitle')} />
        </Grid>
        <Grid container item xs={6} flexDirection="column">
          <Grid container wrap="nowrap" gap={1}>
            <Grid item xs={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTTextField
                  name="firstName"
                  label={t('form.details.firstName')}
                  value={firstName.value}
                  error={firstName.error}
                  onChange={firstName.set}
                  required
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTTextField
                  name="surname"
                  label={t('form.details.surname')}
                  value={surname.value}
                  error={surname.error}
                  onChange={surname.set}
                  required
                />
              )}
            </Grid>
          </Grid>
          <Gap size={24} />
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              name="email"
              label={t('form.details.email')}
              value={email.value}
              error={email.error}
              onChange={email.set}
              required
            />
          )}
          <Gap size={24} />

          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTPhoneNumberField
              id="phone"
              name="mobileNumber"
              label={t('form.details.phone')}
              dialCodes={dialCodes!}
              value={phone.value}
              errors={phone.errors}
              onChange={phone.set}
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <FormTitle text={t('form.jobDetails.title')} />
          <FormSubtitle text={t('form.jobDetails.subtitle')} />
        </Grid>
        <Grid container item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTTextField
              name="jobDescription"
              label={t('form.jobDetails.jobDescription')}
              value={jobTitle.value}
              error={jobTitle.error}
              onChange={jobTitle.set}
              multiline
            />
          )}
          <Gap size={24} />
          {isLoading ? (
            <InputSkeleton />
          ) : workLocations!.length > 0 ? (
            <WCTSelectField
              name="workingLocation"
              id="working-location"
              label={t('form.jobDetails.workLocation')}
              options={workLocations!}
              value={location.value}
              error={location.error}
              onChange={(v) => location.set(v as number)}
            />
          ) : (
            <LabelValueDisplay
              label={t('form.jobDetails.workLocation')}
              value={
                canAddCompanyLocation ? (
                  <>
                    {
                      'There are currently no company locations within the platform - '
                    }
                    <Link href="/company-profile/locations">
                      Add a location
                    </Link>
                  </>
                ) : (
                  <>
                    {
                      'There are currently no company locations within the platform - Please ask your admin to add a location'
                    }
                  </>
                )
              }
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin isLineVisible={false} />
      <div style={footerStyle}>
        <OutlinedButton onClick={() => window.history.back()}>
          Cancel
        </OutlinedButton>
        <PrimaryButton
          disabled={!canSubmit}
          isBusy={isSubmitting}
          type="submit"
        >
          {t('updateButtonLabel')}
        </PrimaryButton>
      </div>
    </form>
  );
}

export default requireAuth(PersonalDetailsPage);
