import { Palette } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const formLabelStyles = (_: Palette): Components['MuiFormLabel'] => ({
  styleOverrides: {
    asterisk: {
      display: 'none',
    },
  },
});
