import { styled } from '@mui/system';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { faPen, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spacer from '@components/layout-util-components/spacer';
import WCTSwitchField from '@components/input/switch-field';
import { Button, Chip, Divider, IconButton, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { Gap } from '@components/layout-util-components/gap';

interface SubItem {
  id: number;
  label: string;
  onEdit: (self: SubItem) => void;
}

interface Props {
  isActive: boolean;
  canEdit: boolean;
  onEdit: () => void;
  name: String;
  onToggleActive: (value: boolean) => void;
  addButton?: {
    label: String;
    onPressed: () => void;
  };
  subItems: Array<SubItem>;
}

const Container = styled(Column)<Props>(({ theme: { palette }, canEdit }) => ({
  borderRadius: 10,
  backgroundColor: palette.white.main,
  color: palette.white.contrastText,
  borderBottom: palette.border.default,
  padding: 20,

  '.item-name': {
    borderRadius: 4,
    padding: '4px 6px 4px 10px',
    backgroundColor: canEdit ? palette.blue.light : palette.greyVeryLight,
    color: canEdit ? palette.common.grey90 : palette.disabledText,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22.88px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    gap: 2,
  },
}));

export default function HierachyItem(props: Props) {
  const {
    isActive,
    canEdit,
    onEdit,
    onToggleActive,
    name,
    addButton,
    subItems,
  } = props;
  const { palette } = useTheme();
  return (
    <Container {...props}>
      <Row>
        <Row className="item-name">
          <span>{name}</span>
          <IconButton disabled={!canEdit} onClick={onEdit}>
            <FontAwesomeIcon
              fontSize={14}
              color={palette.mainBlue as string}
              icon={faPen}
            />
          </IconButton>
        </Row>
        <Spacer />
        <WCTSwitchField
          label="Active"
          value={isActive}
          onChange={onToggleActive}
        />
      </Row>
      {addButton && (
        <Row>
          <Divider
            sx={{ flexGrow: 1, marginLeft: '-20px', marginRight: '20px' }}
          />
          <Button variant="text" onClick={addButton.onPressed}>
            <FontAwesomeIcon icon={faPlus} />
            <span>{addButton.label}</span>
          </Button>
          <Divider
            sx={{ flexGrow: 1, marginRight: '-20px', marginLeft: '20px' }}
          />
        </Row>
      )}
      <Column centerContent={false} sx={{ alignItems: 'start' }}>
        {subItems.map((item) => (
          <Fragment key={item.id}>
            <Gap size={10} />
            <Chip
              variant="outlined"
              label={item.label}
              onDelete={() => item.onEdit(item)}
              deleteIcon={<FontAwesomeIcon icon={faPen} />}
            />
          </Fragment>
        ))}
      </Column>
    </Container>
  );
}
