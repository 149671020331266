import { api } from '@api/api';
import { NestedOption } from '@api/types/nested-option';

const caseTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseTypeOptions: build.query<NestedOption[], void>({
      query: () => ({
        url: 'caseTypeCategory/options',
      }),
    }),
  }),
});

export const { useGetCaseTypeOptionsQuery } = caseTypeApi;
