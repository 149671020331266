import { PrimaryButton } from '@components/buttons';
import RefreshCancelButton from '@components/buttons/refresh-cancel-button';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import WCTSelectField from '@components/input/select-field';
import { ColumnForm } from '@components/layout-util-components/column';
import { Gap } from '@components/layout-util-components/gap';
import { InputSkeleton } from '@components/skeletons';
import useActionFooter from '@hooks/use-action-footer-hook';
import { useAppTr } from '@i18n/use-app-tr';
import { Grid, Typography } from '@mui/material';
import useProfilePreferences from '@pages/app/profile/profile-preferences-hook';

function ProfilePreferencesPage() {
  const footerStyle = useActionFooter();
  const { t } = useAppTr('preferences');

  const {
    timezoneOptions,
    timezone,
    dateFormatOptions,
    dateFormat,
    submitPreferences,
    isSubmitting,
    canSubmit,
    isLoading,
    dateExample,
  } = useProfilePreferences();

  return (
    <ColumnForm
      onSubmit={(e) => {
        e.preventDefault();
        submitPreferences();
      }}
    >
      <Typography variant="h2">{t('title')}</Typography>
      <Gap size={32} />
      {/* <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('language.title')}</Typography>
          <Typography variant="body2">{t('language.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="language"
              options={languageOptions!}
              value={language.value}
              error={language.error}
              onChange={(v) => language.set(v as number)}
            />
          )}
        </Grid>
      </Grid>
      <Divider addMargin /> */}
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('dateDisplay.title')}</Typography>
          <Typography variant="body2">{t('dateDisplay.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="dateDisplay"
              options={dateFormatOptions!}
              value={dateFormat.value}
              error={dateFormat.error}
              onChange={(v) => dateFormat.set(v as number)}
              helperText={t('dateDisplay.helper', {
                formattedDate: dateExample,
              })}
            />
          )}
        </Grid>
      </Grid>
      <Gap size={32} />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('timezone.title')}</Typography>
          <Typography variant="body2">{t('timezone.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTSelectField
              id="timezone"
              options={timezoneOptions!}
              value={timezone.value}
              error={timezone.error}
              onChange={(v) => timezone.set(v as number)}
            />
          )}
        </Grid>
      </Grid>
      {/*<Gap size={32} />*/}
      {/*<Grid container>*/}
      {/*  <Grid item xs={6} flexDirection="column">*/}
      {/*    <Typography variant="body1">{t('timeFormat.title')}</Typography>*/}
      {/*    <Typography variant="body2">{t('timeFormat.message')}</Typography>*/}
      {/*  </Grid>*/}
      {/*  <Grid item xs={6} flexDirection="column">*/}
      {/*    {isLoading ? (*/}
      {/*      <Skeleton />*/}
      {/*    ) : (*/}
      {/*      <RadioSelectGroup*/}
      {/*        options={timeDisplayOptions}*/}
      {/*        value={twentyFourHourClock.value}*/}
      {/*        onChange={twentyFourHourClock.set}*/}
      {/*        error={twentyFourHourClock.error}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      {/* <Divider addMargin />
      <Grid container>
        <Grid item xs={6} flexDirection="column">
          <Typography variant="body1">{t('theme.title')}</Typography>
          <Typography variant="body2">{t('theme.message')}</Typography>
        </Grid>
        <Grid item xs={6} flexDirection="column">
          {isLoading ? (
            <Skeleton />
          ) : (
            <RadioSelectGroup
              options={themeOptions!}
              value={theme.value}
              onChange={theme.set}
              error={theme.error}
            />
          )}
        </Grid>
      </Grid> */}
      <Gap size={32} />
      <div style={footerStyle}>
        <RefreshCancelButton />
        <PrimaryButton
          disabled={!canSubmit}
          isBusy={isSubmitting}
          type="submit"
        >
          {t('updateButtonLabel')}
        </PrimaryButton>
      </div>
    </ColumnForm>
  );
}

export default requireAuth(ProfilePreferencesPage);
