import { useLazyGetNotificationsQuery } from '@api/endpoints/notification.api';
import MenuItemRow from '@components/menu-items/app/menu-item-row';
import { useAppTr } from '@i18n/use-app-tr';
import { Tooltip } from '@mui/material';
import {
  closeNotificationsPanel,
  openNotificationsPanel,
} from '@store/notifications/notifications-slice';
import { selectIsNotificationsOpen } from '@store/notifications/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { selectIsLoggedInAndFullyAuthenticated } from '@store/user/user-selectors';
import { useEffect, useState } from 'react';
import NotificationsIcon from './notifications-icon';

interface Props {
  buttonRef: React.RefObject<HTMLDivElement>;
}

const NotificationMenuItemWrapper: React.FC<Props> = ({ buttonRef }) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [readCount, setReadCount] = useState(0);

  const dispatch = useAppDispatch();
  const { t } = useAppTr('common');
  const canFetchNotifications = useAppSelector(
    selectIsLoggedInAndFullyAuthenticated
  );
  const isNotificationPanelOpen = useAppSelector(selectIsNotificationsOpen);
  const [getNotifications] = useLazyGetNotificationsQuery();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const result = await getNotifications({ skip: 0, take: 25 }).unwrap();
        const unreadCount = result.model.filter((n) => !n.read).length;
        const readCount = result.model.filter((n) => n.read).length;
        setUnreadCount(unreadCount);
        setReadCount(readCount);
      } catch (e) {
        console.error('Notification error: ', e);
      }
    };

    if (canFetchNotifications) {
      fetchNotifications();
      const interval = setInterval(fetchNotifications, 50000);
      return () => clearInterval(interval);
    }
  }, [canFetchNotifications, getNotifications]);

  const isAllCaughtUp = unreadCount === 0 && readCount === 0;

  const handleClick = () => {
    if (isAllCaughtUp) {
      return;
    }

    const action = isNotificationPanelOpen
      ? closeNotificationsPanel
      : openNotificationsPanel;

    dispatch(action());
  };

  return (
    <Tooltip
      title="All caught up..."
      arrow
      placement="top-end"
      disableHoverListener={!isAllCaughtUp}
    >
      <span ref={buttonRef}>
        <MenuItemRow
          onClick={handleClick}
          icon={<NotificationsIcon unreadCount={unreadCount} />}
          isSelected={isNotificationPanelOpen}
          disabled={isAllCaughtUp}
        >
          {t('navBarItems.notifications')}
        </MenuItemRow>
      </span>
    </Tooltip>
  );
};

export default NotificationMenuItemWrapper;
