import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Alert, Button, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import ActionsRow from '@components/layout-util-components/actions-row';
import React from 'react';
import Spacer from '@components/layout-util-components/spacer';
import WCTSwitchField from '@components/input/switch-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { ThemeResource } from '@api/types/theme/theme.resource';
import {
  useDeleteThemeMutation,
  useDisableThemeMutation,
  useEnableThemeMutation,
  useUpdateThemeMutation,
} from '@api/endpoints/company/company-theme.api';
import { usePageAlertVariants } from '@components/alerts';
import useBusyAction from '@hooks/use-busy-action-hook';
import { isApiError } from '@api/types/api-error';
import { UpdateThemeRequest } from '@api/types/theme/update-theme.request';
import { invalidation } from '@api/cache-util';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import useFieldsWatcher from '@hooks/use-fields-watcher';

export interface EditThemeModalProps extends ModalProps<void> {
  theme: ThemeResource;
}

function EditThemeModal({ theme, onClose }: EditThemeModalProps) {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [updateTheme] = useUpdateThemeMutation();
  const [deleteTheme] = useDeleteThemeMutation();
  const [enableTheme] = useEnableThemeMutation();
  const [disableTheme] = useDisableThemeMutation();

  const name = useField<string>([required()], theme.name);
  const isActive = useField<boolean>([required()], !theme.disabled);

  const { validateAll } = useFieldsWatcher([name, isActive]);

  const [onToggleTheme, isTogglingTheme] = useBusyAction(async () => {
    const originalValue = isActive.value;
    try {
      const newValue = !originalValue;
      isActive.set(newValue);
      if (newValue) {
        await enableTheme(theme.themeId).unwrap();
        showSuccessMessage('You have successfully enabled the theme');
      } else {
        await disableTheme(theme.themeId).unwrap();
        showSuccessMessage('You have successfully disabled the theme');
      }

      await invalidation('CompanyTheme');
    } catch (error) {
      if (isApiError(error)) {
        showErrorMessage(error.message);
      }

      isActive.set(originalValue);
    }
  });

  const [onSave, isSaving] = useBusyAction(async () => {
    try {
      if (!validateAll()) {
        return;
      }

      await updateTheme({
        themeId: theme.themeId,
        name: name.value,
      }).unwrap();

      await invalidation('CompanyTheme');

      onClose();
    } catch (error) {
      if (isApiError<UpdateThemeRequest>(error)) {
        showErrorMessage(error.message);
        name.setError(error.errors?.name);
      }
    }
  });

  const [onDeleteTheme, isDeleting] = useBusyAction(async () => {
    try {
      await deleteTheme(theme.themeId).unwrap();

      await invalidation('CompanyTheme');

      onClose();
    } catch (error) {
      if (isApiError(error)) {
        showErrorMessage(error.message);
      }
    }
  });

  return (
    <Column>
      <Typography>Edit Theme</Typography>
      <Gap size={20} />
      {theme.hasRca && (
        <>
          <Alert variant="filled" severity="info">
            Some edit functionality is restricted as this is in use
          </Alert>
          <Gap size={20} />
        </>
      )}
      <WCTTextField
        name="theme_name"
        label="Rename Theme"
        value={name.value}
        onChange={name.set}
        error={name.error}
        readonly={theme.hasRca}
      />
      <Gap size={20} />
      <WCTSwitchField
        label="Active"
        value={isActive.value}
        onChange={(_) => onToggleTheme()}
        error={isActive.error}
        sx={{ alignSelf: 'flex-end' }}
      />
      <Gap size={20} />
      <ActionsRow>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <Spacer />
        <OutlinedButton
          onClick={onDeleteTheme}
          disabled={theme.hasRca || isSaving || isTogglingTheme}
          isBusy={isDeleting}
        >
          <FontAwesomeIcon icon={faTrash} />
          Delete Theme
        </OutlinedButton>
        <PrimaryButton
          onClick={onSave}
          disabled={isDeleting || isTogglingTheme}
          isBusy={isSaving}
        >
          Save
        </PrimaryButton>
      </ActionsRow>
    </Column>
  );
}

export default withModal(EditThemeModal);
