import SectionScaffold from '@components/outcome-report/components/section-scaffold';
import { styled, Typography } from '@mui/material';
import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import RenderGraph from '@pages/app/rca/tabs/components/graph/render-graph';
import { RcaUtil } from '@util/rca-util';
import { useAppDispatch } from '@store/store';
import chainApi from '@api/endpoints/chain/chain.api';
import { useEffect } from 'react';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import HealthBadge from '@components/badges/health-badge';
import { PrimaryButton } from '@components/buttons';
import { Gap } from '@components/layout-util-components/gap';
import ChartControls from '@pages/app/rca/tabs/components/chart-controls';

interface Props {
  chainDetail: ChainDetailResponse;
  themeCount: number;
  healthScore: number;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  position: 'relative',
  width: '100vw',
  height: '100vh',

  '.title': {
    fontSize: '28px',
    fontWeight: '400',
    lineHeight: '1',
    // top: 60,
    textAlign: 'center',
    // position: 'absolute',
    // left: 'calc(50% - 120px)',
    // width: 240,
    zIndex: 1000,
    marginBottom: '5px',
  },

  '.wct-primary-button': {
    position: 'absolute',
    bottom: 40,
    right: 40,
    zIndex: 1000,
  },

  '.input-blocker': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',

    zIndex: 1000,
  },

  '.info-container': {
    position: 'absolute',
    top: 30,
    left: 30,
  },

  '.overview-container': {
    background: '#fff',
    borderRadius: 8,
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    zIndex: 2000,
    minWidth: 300,

    '.custom-row': {
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 25,
    },

    '.data-value': {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      '&:last-child': {
        minWidth: '125px',
      },

      '.label': {
        color: '#949FA2',
        fontSize: '13px',
        fontWeight: '500',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
      },

      '.value': {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0px',
        '&.numeric': {
          textAlign: 'center',
          background: '#CDF1F3',
          color: '#00223A',
          padding: '3px 6px',
          borderRadius: 4,
        },
        '> div': {
          padding: '5px 10px',
          border: '1px solid',
          '.indicator': {
            width: '12px',
            height: '12px',
            borderRadius: '6px',
          },
        },
      },
    },
  },
}));

export default function ChartSection({
  chainDetail,
  themeCount,
  healthScore,
}: Props) {
  const { caseId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const metaDataRequest = dispatch(
      chainApi.endpoints.getChainMetaData.initiate(chainDetail.chainId, {
        subscribe: true,
        forceRefetch: true,
      })
    );
    return () => {
      metaDataRequest.unsubscribe();
    };
  }, [chainDetail.chainId, dispatch]);

  return (
    <SectionScaffold id="chart">
      <Container>
        <RenderGraph
          onInit={(reactFlowInstance) => {
            RcaUtil.setReactFlowInstance(reactFlowInstance);

            reactFlowInstance.fitView({
              nodes: chainDetail.nodes,
              // padding: 0.5,
              minZoom: 0.2,
              // maxZoom: 0.1,
            });

            // const vp = reactFlowInstance.getViewport();
            // vp.x += 40;
            // reactFlowInstance.setViewport(vp);
          }}
          nodes={chainDetail.nodes}
          edges={chainDetail.edges}
          preventScrolling={false}
        >
          <ChartControls onlyZoom />
        </RenderGraph>

        <Column className="info-container" centerContent>
          <Typography className="title">Analysis</Typography>
          <Gap size={15} />
          <div className="overview-container">
            <Column>
              <Row>
                <div className="data-value">
                  <div className="label">CAUSE BOXES</div>
                  <div className="value numeric">
                    {chainDetail.nodes.length}
                  </div>
                </div>
                <div className="data-value">
                  <div className="label">THEMES</div>
                  <div className="value numeric">{themeCount}</div>
                </div>
                <div className="data-value">
                  <div className="label">Health Score</div>
                  <div className="value">
                    <HealthBadge health={healthScore} />
                  </div>
                </div>
              </Row>
            </Column>
          </div>
        </Column>
        <PrimaryButton href={`/rca/tabs/${caseId}/chart`}>
          View Analysis
          <FontAwesomeIcon icon={faArrowRight} />
        </PrimaryButton>
      </Container>
    </SectionScaffold>
  );
}
