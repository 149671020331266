import useMyGroups from '@pages/app/groups/my-groups-hook';
import { Box, Typography } from '@mui/material';
import { useAppTr } from '@i18n/use-app-tr';
import { GroupCard } from '@components/cards';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { LoadingIndicator } from '@components/loading-indicator';
import { LinkButton, PrimaryButton } from '@components/buttons';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { CardLayout } from '@components/layout-util-components/card-layout';
import NoGroupsInfo from '../profile/components/no-groups-info';
import ContentContainer from '@components/containers/content-container';
import { Gap } from '@components/layout-util-components/gap';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { SortByFilter, WCTTableFilters } from '@components/table';
import NoFilteredGroupsInfo from './components/no-filtered-groups-info';
import Spacer from '@components/layout-util-components/spacer';
import { ButtonSkeleton } from '@components/skeletons';
import Row from '@components/layout-util-components/row';
import GroupForm from '@pages/app/groups/forms/group-form';
import useOpenGroupOnMount from '@hooks/groups/use-open-group-on-mount';

type QueryParams = {
  companyGroupId?: string;
};

function MyGroupsPage() {
  const { companyGroupId } = useSearchParamsTyped<QueryParams>();
  const { showPopup } = useUiPopup();

  const { t } = useAppTr('groups');

  const {
    isLoading,
    groups,
    loadMore,
    showLoadMoreButton,
    search,
    sort,
    sortByOptions,
    hasNoGroups,
    hasFilteredGroups,
    hasNoFilteredGroups,
    canCreateGroups,
  } = useMyGroups();

  useOpenGroupOnMount(companyGroupId);

  return (
    <ContentContainer>
      <>
        <Row>
          <Typography variant="h1">
            {t('title.user', { count: groups?.totalCount ?? 0 })}
          </Typography>
          <Spacer />
          {isLoading ? (
            <ButtonSkeleton noGrow />
          ) : canCreateGroups && !hasNoGroups ? (
            <PrimaryButton onClick={() => showPopup(GroupForm)}>
              Create Group
            </PrimaryButton>
          ) : null}
        </Row>
        <Gap size={20} />
        <WCTTableFilters
          search={search.value}
          onSearch={search.set}
          isLoading={isLoading}
          rightOfSearchContent={
            <SortByFilter
              value={sort.value}
              onChange={sort.set}
              options={sortByOptions}
            />
          }
        />
        <Gap size={20} />
        {hasNoGroups && <NoGroupsInfo />}
        {hasNoFilteredGroups && <NoFilteredGroupsInfo />}
        {hasFilteredGroups && (
          <>
            <CardLayout>
              {isLoading ? (
                <LoadingIndicator show />
              ) : (
                groups!.model.map((group) => (
                  <GroupCard
                    key={group.companyGroupId}
                    group={group}
                    allowLeave
                  />
                ))
              )}
            </CardLayout>
            <Gap size={15} />
            <Box alignSelf="center">
              {showLoadMoreButton ? (
                <LinkButton disabled={isLoading} onClick={loadMore}>
                  {t('loadMoreButtonLabel')}
                </LinkButton>
              ) : null}
            </Box>
          </>
        )}
      </>
    </ContentContainer>
  );
}

export default requireAuth(MyGroupsPage);
