import withModal, { ModalProps } from '@components/ui-popup/modals/modal-hoc';
import Column from '@components/layout-util-components/column';
import { Button, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import useField from '@hooks/use-field-hook';
import { required } from '@util/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';
import ActionsRow from '@components/layout-util-components/actions-row';
import React, { Fragment, useMemo } from 'react';
import Spacer from '@components/layout-util-components/spacer';
import { Option } from '@api/types/option';
import Row from '@components/layout-util-components/row';
import { useCreateChildThemeMutation } from '@api/endpoints/company/company-theme.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { PrimaryButton } from '@components/buttons';
import { isApiError } from '@api/types/api-error';
import { CreateChildThemeRequest } from '@api/types/theme/create-child-theme.request';
import { usePageAlertVariants } from '@components/alerts';
import { invalidation } from '@api/cache-util';

export interface AddThemeModalProps extends ModalProps<void> {
  categoryId: number;
}

function AddThemeModal({ onClose, categoryId }: AddThemeModalProps) {
  const { showErrorMessage } = usePageAlertVariants();
  const [createChildTheme] = useCreateChildThemeMutation();

  const themes = useField<Array<Option>>(
    [required()],
    useMemo(() => [{ id: -1, label: '' }], [])
  );

  const firstTheme = themes.value.at(0);
  const restThemes = themes.value.slice(1);

  const [onSave, isBusy] = useBusyAction(async () => {
    try {
      const names = themes.value.map((theme) => theme.label);
      await createChildTheme({ parentId: categoryId, names }).unwrap();

      await invalidation('CompanyTheme');

      onClose();
    } catch (e) {
      if (isApiError<CreateChildThemeRequest>(e)) {
        showErrorMessage(e.message);
        themes.setError(e.errors);
      }
    }
  });

  return (
    <Column>
      <Typography>Add Theme</Typography>
      <Gap size={20} />
      <WCTTextField
        name="value"
        label="Theme Title"
        value={firstTheme?.label}
        onChange={(value) => {
          themes.set([{ ...firstTheme, label: value }, ...restThemes]);
        }}
        error={themes.errors?.[0]}
      />
      <Gap size={20} />
      {restThemes.map((theme, i) => (
        <Fragment key={theme.id || i + 1}>
          <Row>
            <WCTTextField
              name={`theme_${i}_title`}
              label="Theme Title"
              value={theme.label}
              onChange={(value) => {
                themes.set(
                  themes.value.map((t, j) =>
                    i === j - 1 ? { ...t, label: value } : t
                  )
                );
              }}
              error={themes.errors?.[i]}
            />
            <Gap size={10} />
            <Button
              variant="text"
              size="large"
              onClick={() => themes.set(themes.value.filter((_, j) => i !== j))}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Row>
          <Gap size={20} />
        </Fragment>
      ))}
      <Button
        variant="text"
        sx={{ alignSelf: 'flex-start' }}
        onClick={() => {
          themes.set([...themes.value, '']);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add another Theme
      </Button>
      <Gap size={20} />
      <ActionsRow>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <Spacer />
        <PrimaryButton onClick={onSave} isBusy={isBusy}>
          Save
        </PrimaryButton>
      </ActionsRow>
    </Column>
  );
}

export default withModal(AddThemeModal);
