import { ReactComponent as BarsIcon } from '@assets/svgs/bars.svg';
import { ReactComponent as Grid2Icon } from '@assets/svgs/grid-2.svg';
import { ReactComponent as LocationDotIcon } from '@assets/svgs/location-dot.svg';
import PrimaryButton from '@components/buttons/primary-button';
import ContentContainer from '@components/containers/content-container';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Gap } from '@components/layout-util-components/gap';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { LoadingIndicator } from '@components/loading-indicator';
import { ButtonSkeleton } from '@components/skeletons';
import {
  DropDownFilter,
  SortByFilter,
  WCTTableFilters,
} from '@components/table';
import RadioFilter from '@components/table/filters/radio-filter';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { Typography } from '@mui/material';
import useAnalysis, {
  AnalysisViewMode,
} from '@pages/app/analysis/analysis-overview-hook';
import AnalysisGridView from '@pages/app/analysis/components/analysis-grid-view';
import AnalysisMapView from '@pages/app/analysis/components/analysis-map-view';
import AnalysisTableView from '@pages/app/analysis/components/analysis-table-view';
import NoRcaInfo from '@pages/app/analysis/components/no-rca-info';
import { numberFromString } from '@util/string-util';
import { useNavigate } from 'react-router-dom';

interface Params {
  person?: string;
  group?: string;
  incidentLocation?: string;
}

function AnalysisOverviewPage() {
  const {
    person: personId,
    incidentLocation: incidentLocationId,
    group: groupId,
  } = useSearchParamsTyped<Params>();
  const navigate = useNavigate();

  const state = useAnalysis(
    numberFromString(personId),
    numberFromString(incidentLocationId),
    numberFromString(groupId)
  );

  const {
    isLoading,
    isFetching,
    viewMode,
    search,
    status,
    person,
    group,
    sort,
    caseData,
    userOptions,
    statusOptions,
    groupOptions,
    sortByOptions,
    category,
    caseAnalysisTypeOptions,
  } = state;

  const onCreateRcaClick = () => {
    navigate('/rca/create');
  };

  return (
    <ContentContainer maxWidth={1500}>
      <Row>
        <Typography variant="h1">RCAs</Typography>
        <Spacer />
        {isLoading ? (
          <ButtonSkeleton noGrow />
        ) : caseData!.totalCount > 0 ? (
          <PrimaryButton onClick={onCreateRcaClick}>Create RCA</PrimaryButton>
        ) : null}
      </Row>
      <Gap size={32} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : caseData!.totalCount === 0 ? (
        <NoRcaInfo onCreateClicked={onCreateRcaClick} />
      ) : (
        <>
          <Gap size={32} />
          <WCTTableFilters
            isLoading={isLoading}
            isFetching={isFetching}
            search={search.value}
            onSearch={search.set}
            rightOfSearchContent={
              <SortByFilter
                value={sort.value}
                onChange={sort.set}
                options={sortByOptions}
              />
            }
          >
            <DropDownFilter
              options={caseAnalysisTypeOptions!.map((opt) => ({
                id: opt.caseAnalysisTypeId,
                label: opt.name,
              }))}
              value={category.value}
              onChange={category.set}
              multiple
            >
              RCA Type
            </DropDownFilter>
            <DropDownFilter
              value={status.value}
              onChange={status.set}
              options={statusOptions ?? []}
              styledOptions={{
                marginLeft: 'auto',
              }}
              multiple
            >
              Status
            </DropDownFilter>
            <DropDownFilter
              value={person.value}
              onChange={person.set}
              options={userOptions ?? []}
              multiple
            >
              Assigned
            </DropDownFilter>
            <DropDownFilter
              value={group.value}
              onChange={group.set}
              options={groupOptions ?? []}
              multiple
            >
              Group
            </DropDownFilter>
            <RadioFilter
              options={[
                { id: AnalysisViewMode.grid, label: <Grid2Icon /> },
                { id: AnalysisViewMode.table, label: <BarsIcon /> },
                { id: AnalysisViewMode.map, label: <LocationDotIcon /> },
              ]}
              className="ml-auto"
              value={viewMode.value}
              onChange={viewMode.set}
            />
          </WCTTableFilters>
          <Gap size={24} />
          {viewMode.value === AnalysisViewMode.table ? (
            <AnalysisTableView {...state} />
          ) : viewMode.value === AnalysisViewMode.grid ? (
            <AnalysisGridView {...state} />
          ) : viewMode.value === AnalysisViewMode.map ? (
            <AnalysisMapView {...state} />
          ) : null}
        </>
      )}
    </ContentContainer>
  );
}

export default requireAuth(AnalysisOverviewPage);
