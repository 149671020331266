import requireAuth from '@components/hoc/with-required-auth-hoc';
import RcaCreateScaffold from '@pages/app/rca/create/components/rca-create-scaffold';
import RcaStepperAppBar from '@pages/app/rca/create/components/rca-stepper-app-bar';
import useStepper, { StepperStep } from '@components/stepper/use-stepper-hook';
import OverviewStep from '@pages/app/rca/create/steps/overview-step';
import ImpactStep from '@pages/app/rca/create/steps/impact-step';
import CollaborationStep from '@pages/app/rca/create/steps/collaboration-step';
import { useNavigate } from 'react-router-dom';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { useEffect } from 'react';
import { numberFromString } from '@util/string-util';
import { store, useAppDispatch } from '@store/store';
import { selectCurrentCaseId } from '@store/create-analysis-process/selectors';
import HelpSideBar from '@pages/app/rca/create/components/help-side-bar';

export interface RcaStepProps {
  onComplete: () => void;
  onGoBack: () => void;
  caseId?: number;
}

type CreateRcaQueryParams = {
  type: string;
  caseId?: string;
  step: string;
};

const steps: Array<StepperStep<RcaStepProps>> = [
  { title: 'Details', Component: OverviewStep },
  { title: 'Impacts', Component: ImpactStep },
  { title: 'Collaborate', Component: CollaborationStep },
];

function RcaCreationStepsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const stepper = useStepper<RcaStepProps>(steps);
  const {
    setCurrentStep,
    currentStepIndex,
    currentStep: { Component: CurrentStepComponent },
  } = stepper;

  const { step, caseId } = useSearchParamsTyped<CreateRcaQueryParams>();
  const stepNumber = numberFromString(step);
  const caseIdNumber = numberFromString(caseId);

  useEffect(() => {
    // const typeId = numberFromString(type);
    // if (typeId == null) {
    //   navigate('/rca/create');
    // } else {
    //   dispatch(setAnalysisCreationType(typeId));
    // }

    if (stepNumber != null && stepNumber !== currentStepIndex) {
      setCurrentStep(stepNumber);
    }
  }, [dispatch, navigate, setCurrentStep, stepNumber, currentStepIndex]);

  const buildQueryParams = (step: number) => {
    const caseId = selectCurrentCaseId(store.getState()) ?? caseIdNumber;
    if (caseId != null) {
      return `/rca/create/steps?&step=${step}&caseId=${caseId}`;
    } else {
      return `/rca/create/steps?&step=${step}`;
    }
  };

  const onComplete = () => {
    const nextStep = currentStepIndex + 1;
    if (nextStep < steps.length) {
      navigate(buildQueryParams(nextStep));
    } else {
      const caseId = (selectCurrentCaseId(store.getState()) ?? caseIdNumber)!;
      navigate(`/rca/tabs/${caseId}`);
    }
  };

  const onGoBack = () => {
    const prevStep = currentStepIndex - 1;
    if (prevStep >= 0) {
      navigate(buildQueryParams(prevStep));
    } else {
      navigate('/rca/create');
    }
  };

  return (
    <RcaCreateScaffold
      appbar={<RcaStepperAppBar stepper={stepper} onBack={onGoBack} />}
      sidebar={<HelpSideBar />}
    >
      <CurrentStepComponent
        onGoBack={onGoBack}
        onComplete={onComplete}
        caseId={caseIdNumber}
      />
    </RcaCreateScaffold>
  );
}

export default requireAuth(RcaCreationStepsPage);
