import { SolutionsPanelState } from '@pages/app/rca/tabs/components/editor-panels/solutions-panel-hook';
import useCreateFromCaseSolutionForm from '@pages/app/rca/tabs/components/editor-panels/components/solutions-panel/create-from-case-solution-form-view-hook';
import Column, { ColumnForm } from '@components/layout-util-components/column';
import CaseSolutionItem from '@components/cards/case-solution-item';
import { Box, Skeleton, Typography } from '@mui/material';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import { Divider } from '@components/layout-util-components/divider';
import WCTCurrencyField from '@components/input/currency-field';
import { InputSkeleton } from '@components/skeletons';
import InlineRadioSelectGroup from '@components/input/inline-radio-select-group';
import ActionsRow from '@components/layout-util-components/actions-row';
import {
  FlatButton,
  LinkButton,
  OutlinedButton,
  PrimaryButton,
} from '@components/buttons';
import { useBinaryOptions } from '@util/constant-options';
import { Gap } from '@components/layout-util-components/gap';
import { formatCurrency } from '@util/currency-util';
import getSymbolFromCurrency from 'currency-symbol-map';
import WCTSliderField from '@components/input/slider-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus } from '@fortawesome/pro-light-svg-icons';
import { SolutionTypeUtil } from '@util/solution-type-util';

interface Props {
  state: SolutionsPanelState;
}

const marks = [
  {
    value: 5,
    label: (
      <>
        Very <br /> Low
      </>
    ),
  },
  {
    value: 10,
    label: '',
  },
  {
    value: 15,
    label: '',
  },
  {
    value: 20,
    label: '',
  },
  {
    value: 25,
    label: '',
  },
  {
    value: 30,
    label: '',
  },
  {
    value: 35,
    label: '',
  },
  {
    value: 40,
    label: '',
  },
  {
    value: 45,
    label: '',
  },
  {
    value: 50,
    label: '',
  },
  {
    value: 55,
    label: 'Medium',
  },
  {
    value: 60,
    label: '',
  },
  {
    value: 65,
    label: '',
  },
  {
    value: 70,
    label: '',
  },
  {
    value: 75,
    label: '',
  },
  {
    value: 80,
    label: '',
  },
  {
    value: 85,
    label: '',
  },
  {
    value: 90,
    label: '',
  },
  {
    value: 95,
    label: '',
  },
  {
    value: 100,
    label: '',
  },
  {
    value: 105,
    label: (
      <>
        Very <br /> High
      </>
    ),
  },
];

const easeOfImplementationMarks = [
  {
    value: 0,
    label: '',
  },
  {
    value: 1,
    label: (
      <>
        Very <br /> Easy
      </>
    ),
  },
  {
    value: 2,
    label: '',
  },
  {
    value: 3,
    label: '',
  },
  {
    value: 4,
    label: '',
  },
  {
    value: 5,
    label: (
      <>
        Very <br /> Difficult
      </>
    ),
  },
];

export default function CreateFromCaseSolutionFormView({ state }: Props) {
  const { cancelCreateNewSolution, gotoViewList, updateCaseSolution } = state;

  const {
    additionalCost,
    submit,
    caseSolution,
    canSubmit,
    termOptions,
    term,
    isLoading,
    effectiveness,
    solutionTypes,
    type,
    isBusy,
    showAdditionalCost,
    setShowAdditionalCost,
    currency,
    canContribute,
    canShowSolutionCost,
    currentEffectivenessValue,
    onChangeEffectiveness,
    formatEffectivenessValue,
    formatEaseOfImplementationValue,
    easeOfImplementation,
  } = useCreateFromCaseSolutionForm(state);

  const binaryOptions = useBinaryOptions();

  const onSubmit = async () => {
    if (await submit()) {
      gotoViewList();
    }
  };

  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <CaseSolutionItem
        solution={caseSolution}
        displayCost
        currency={currency}
        trailing={
          canContribute ? (
            <FlatButton onClick={() => updateCaseSolution(caseSolution)}>
              <FontAwesomeIcon icon={faPencil} />
            </FlatButton>
          ) : undefined
        }
      />
      <Divider addMargin negativeMargin={-25} compact />
      <Typography variant="body1">
        {isLoading ? (
          <Skeleton />
        ) : (
          'Please answer the questions below to assign the above solution to this Cause Box'
        )}
      </Typography>
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <Column sx={currentEffectivenessValue === 0 ? { opacity: 0.5 } : {}}>
          <Typography>Estimated Effectiveness</Typography>
          <Gap size={15} />
          <WCTSliderField
            name={'Efficacy'}
            value={currentEffectivenessValue}
            onChange={onChangeEffectiveness}
            step={5}
            max={105}
            formatValue={formatEffectivenessValue}
            marks={marks}
            readonly={!canContribute}
            error={effectiveness.error}
            valueLabelDisplay="on"
          />
        </Column>
      )}
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <InlineRadioSelectGroup
          options={termOptions!}
          name="term"
          label="Solution Term"
          onChange={term.set}
          error={term.error}
          value={term.value}
        />
      )}
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <InlineRadioSelectGroup
          options={solutionTypes!.map((x) => ({
            id: x.id,
            label: `${x.label} [${SolutionTypeUtil.toShortLabel(x.label)}]`,
          }))}
          name="type"
          label="Solution Type"
          onChange={type.set}
          error={type.error}
          value={type.value}
        />
      )}
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <InlineRadioSelectGroup
          options={binaryOptions!}
          name="showAdditionalCost"
          label="Additional cost to implement?"
          onChange={(v) => setShowAdditionalCost((v as boolean) ?? false)}
          value={showAdditionalCost}
        />
      )}
      {showAdditionalCost && (
        <>
          <Gap size={15} />
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <WCTCurrencyField
              name="additionalCost"
              label="Additional Cost"
              onChange={additionalCost.set}
              error={additionalCost.error}
              value={additionalCost.value}
              defaultValue={additionalCost.defaultValue}
              currency={currency}
            />
          )}

          {canShowSolutionCost ? (
            <>
              <Gap size={4} />
              <Typography variant="caption">
                Initial cost:{' '}
                {`${getSymbolFromCurrency(currency)}${formatCurrency(
                  caseSolution!.cost!,
                  currency
                )}`}
              </Typography>
            </>
          ) : null}
        </>
      )}
      <Gap size={15} />
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <Column sx={!easeOfImplementation.value ? { opacity: 0.5 } : {}}>
          <Typography>Ease of Implementation</Typography>
          <Gap size={15} />

          <WCTSliderField
            name={'EaseOfImplementation'}
            value={easeOfImplementation.value}
            onChange={easeOfImplementation.set}
            step={1}
            max={5}
            marks={easeOfImplementationMarks}
            formatValue={formatEaseOfImplementationValue}
            readonly={!canContribute}
            error={easeOfImplementation.error}
            valueLabelDisplay="on"
            valueLabelStyles={{
              0: {
                '& .MuiSlider-valueLabel': {
                  left: '-15px',
                  '&:before': {
                    left: '25%',
                  },
                },
              },
              5: {
                '& .MuiSlider-valueLabel': {
                  right: '-15px',
                  '&:before': {
                    left: '78%',
                  },
                },
              },
            }}
          />
        </Column>
      )}

      <Divider addMargin negativeMargin={-25} compact />
      <ActionsRow>
        <OutlinedButton onClick={cancelCreateNewSolution}>
          Cancel
        </OutlinedButton>
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          Save
        </PrimaryButton>
      </ActionsRow>
    </ColumnForm>
  );
}
