import { BorderColor } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  PopperProps,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import React from 'react';

interface DefaultTooltipProps {
  image?: React.ReactElement;
  content?: React.ReactNode;
}

const Container = styled(Box)(({ theme }) => ({
  height: 130,
  display: 'flex',
  alignItems: 'center',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '25%',
  // height: 130,
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  '& svg': {
    width: '80%',
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  height: 130,
  backgroundColor: '#CDF1F366',
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
  alignItems: 'center',
  justifyContent: 'center',
  color: '#001927',
  fontSize: 12,
  padding: 15,
}));

interface HtmlTooltipProps extends TooltipProps {
  clickedAway: () => void;
}

export const HtmlTooltip = styled(
  ({ className, clickedAway, ...props }: HtmlTooltipProps) => (
    <ClickAwayListener onClickAway={clickedAway}>
      <Tooltip
        {...props}
        classes={{ popper: className }}
        placement="right"
        disableInteractive
        PopperProps={{
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom-end'], // Add bottom-right as fallback
              },
            },
            {
              name: 'offset',
              options: {
                offset: [30, 0],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                enabled: true,
                boundariesElement: 'viewPort',
              },
            },
          ],
        }}
      />
    </ClickAwayListener>
  )
)(({ theme }) => ({
  width: 400,
  border: 0,
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 385,
    backgroundColor: '#fff',
    border: '1px solid #000',
    borderRadius: 10,
    padding: 0,
    '& > div': {
      padding: 0,
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    // backgroundColor: '#fff',
    '&::before': {
      border: '1px solid #000',
    },
  },
  [`&[data-popper-placement*="left"] .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: '#EBF9FA',
    },
  },
  [`&[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: '#EBF9FA',
    },
  },
  [`&[data-popper-placement*="bottom"] .${tooltipClasses.arrow}`]: {
    marginLeft: 7,
    '&::before': {
      backgroundColor: '#EBF9FA',
    },
  },
  [`&[data-popper-placement*="right"] .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: '#fff',
    },
  },
}));

export const ToolTipP = styled(Typography)(({ theme }) => ({
  marginBottom: 15,
  color: '#001927',
  fontSize: 12,
}));

export default function DefaultTooltip({
  image,
  content,
}: DefaultTooltipProps) {
  return (
    <Container>
      {image && <ImageContainer>{image}</ImageContainer>}
      {content && <ContentContainer>{content}</ContentContainer>}
    </Container>
  );
}
