import { Button, darken, Palette, styled } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';
import { ButtonProps } from './button-props';
import { LoadingIndicator } from '@components/loading-indicator';

const colorMap = (palette: Palette) => ({
  primary: {
    text: palette.blue.dark as string,
    bg: 'rgba(255, 108, 0)',
    bgImage: `linear-gradient(90deg, ${palette.primary.main} 0%, ${palette.primary.main} 100%)`,
    bgDisabled: 'rgba(255, 108, 0, 0.5)',
  },
  info: {
    text: palette.white.main,
    bg: palette.mainBlue,
    bgImage: 'none',
    bgDisabled: 'darken($palette.main.blue, 0.5)',
  },
});

const StyledButton = styled(Button, { target: 'wct-primary-button' })<{
  colorStyle: 'primary' | 'info';
}>(({ theme: { palette }, colorStyle }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  boxShadow: 'none',
  lineHeight: '26px',
  backgroundColor: colorMap(palette)[colorStyle].bg,
  backgroundImage: colorMap(palette)[colorStyle].bgImage,
  '&:hover': {
    backgroundColor: darken(colorMap(palette)[colorStyle].bg as string, 0.2),
    boxShadow: 'none',
  },
  '&.Mui-disabled': {
    color: colorMap(palette)[colorStyle].text,
    backgroundColor: colorMap(palette)[colorStyle].bgDisabled,
    backgroundImage: 'none',
    opacity: 1,
    '& .button-content': {
      opacity: 0.8,
    },
  },
  '&.MuiButton-sizeLarge': {
    height: 56,
    fontSize: '15px',
    fontWeight: 500,
    borderRadius: 4,
  },
  '&.MuiButton-sizeMedium': {
    height: 40,
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: 4,
  },
  padding: '8px 22px',
  gap: '8px',
  color: colorMap(palette)[colorStyle].text,
}));

type Props = ButtonProps & {
  isBusy?: boolean;
  color?: 'primary' | 'info';
  size?: 'large' | 'medium' | 'small';
  styleOptions?: CSSProperties;
  form?: string;
  simulateDisabledState?: boolean;
};

const PrimaryButton = ({
  id,
  children,
  onClick,
  disabled,
  color = 'primary',
  isBusy,
  type,
  size,
  form,
  href,
}: PropsWithChildren<Props>) => {
  return (
    <StyledButton
      id={id}
      variant="contained"
      type={type ?? 'button'}
      colorStyle={color}
      onClick={onClick}
      disabled={disabled || isBusy}
      size={size}
      form={form}
      href={href}
    >
      <span
        className="button-content"
        style={{ visibility: isBusy ? 'hidden' : 'visible' }}
      >
        {children}
      </span>
      <LoadingIndicator show={isBusy ?? false} isWhite />
    </StyledButton>
  );
};

export default PrimaryButton;
