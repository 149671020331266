import { ComponentType } from 'react';
import {
  Modal,
  ModalProps as MuiModalProps,
  Paper,
  PaperProps,
  styled,
} from '@mui/material';
import { UiPopupProps } from '@components/ui-popup/ui-popup-type';

export interface ModalProps<T> extends UiPopupProps<T> {}

interface HOCOptions {
  noPadding?: boolean;
  blurBackdrop?: boolean;
}

const StyledModal = styled(Modal)<MuiModalProps & HOCOptions>(
  ({ blurBackdrop }) => ({
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: blurBackdrop ? 'blur(15px)' : undefined,
  })
);

const StyledContainer = styled(Paper)<HOCOptions & PaperProps>(
  ({ theme, noPadding }) => ({
    position: 'absolute',
    width: 900,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: noPadding ? 0 : 20,
    outline: 0,
    borderBottom: theme.palette.border.dividers,
    borderRadius: 10,
    '> div': {
      width: '100%',
    },
  })
);

export default function withModal<P extends ModalProps<T>, T = any>(
  Component: ComponentType<P>,
  opt: HOCOptions = { noPadding: false, blurBackdrop: false }
) {
  return (props: P) => {
    const { show, onClose } = props;

    return (
      <StyledModal open={show} onClose={() => onClose()} {...opt}>
        <StyledContainer {...opt}>
          <Component {...props} />
        </StyledContainer>
      </StyledModal>
    );
  };
}
